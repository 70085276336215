<template>
<div class="content-wrapper">
  <bo-page-header></bo-page-header>
  <div class="content pt-0">
    <b-card>
      <b-row>
        <b-col md="8">
          <div class="project_frm_wrap">
            <b-form @submit.prevent="addProject">
              <b-row>
                <b-col md="8">
                  <b-form-group
                    label-for="projectName"
                  >
                    <template #label>
                      Project Name <small class="mandatory_txt">*</small>
                    </template>
                    <div class="form-group-feedback form-group-feedback-left">
                      <b-form-input placeholder="e.g. Rancang Mebel" id="projectName" />
                      <div class="form-control-feedback">
                        <i class="icon-file-text2"></i>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                
                <b-col md="5">
                  <b-form-group label-for="projectKeyword">
                    <template #label>
                      Keyword <small class="mandatory_txt">*</small>
                    </template>
                    <b-input-group>
                      <b-form-input id="projectKeyword" placeholder="e.g. Custom Furniture" />
                      <template #append>
                        <b-button variant="light"><i class="icon-plus2 mr-0"></i></b-button>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Hashtag" label-for="projectHashtag">
                <b-form-tags tag-class="bg-indigo-400"/>
              </b-form-group>
              <b-button class="bg-indigo-400" type="submit" variant="transparent" v-b-modal>Create Project</b-button>
            </b-form>
            <b-modal
              centered
              id="addProjectProcess"
              hide-footer
              hide-header
            >
              <div class="wrap_info_loader pb-2">
                <h3>Processing Data... </h3>
                <i class="icon-spinner2 spinner"></i>

                <p><span id="counter">{{ counter }}</span> second(s).</p>
              </div>
            </b-modal>
          </div>
        </b-col>
        <b-col md="4">
          <div class="graphic_info">
            <img class="img-responsive" src="global_assets/images/graphic1.jpg" />
            <h4>Information</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
              et dolore magna aliqua
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
  <bo-footer></bo-footer>
</div>
</template>
<script>
const _ = require('lodash')
import projectsData from '@/dummies/projectsData'
import GlobalVue from '../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
      counter: 0,
      projectAdded: false,
    }
  },
  methods: {
    addProject() {
      this.counter = 10
      this.$bvModal.show('addProjectProcess')
      projectsData.addData(
        {
          name: 'Proj1',
          keyword: 'Proj1',
          hashtag: 'Proj1',
        }
      )
    },
  },
  watch: {
    counter: {
      handler(val) {
        if (val > 0) {
          this.projectAdded = true
          setTimeout(() => {
            this.counter--
          }, 1000)
        }else if (val == 0 && this.projectAdded) {
          this.$bvModal.hide('addProjectProcess')
          this.$router.push({ name: 'ProjectDashboard', params: { project: _.kebabCase(this.projName) } })
        }
      },
      immediate: true,
    }
  }
}
</script>